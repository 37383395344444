import type { Link } from '@backmarket/http-api'

export function removePattern(link: Link): Link {
  if ('pattern' in link) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { pattern, ...linkWithoutPattern } = link

    return linkWithoutPattern
  }

  return link
}

export function removePatternFromLink<T>(obj: T & { link: Link }) {
  const { link, ...rest } = obj

  return {
    ...rest,
    link: removePattern(link),
  } as T & { link: Link }
}
