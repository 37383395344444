import { createError } from '#imports'
import { type MaybeRefOrGetter } from 'vue'

import type { NuxtError } from 'nuxt/app'

import { useFetchArticlesHub } from './useFetchArticlesHub'
import { useHubHead } from './useHubHead'
import { useTrackBreadcrumbClick } from './useTrackBreadcrumbClick'

function handleError(error: NuxtError | null) {
  if (error)
    throw createError({
      statusMessage: error.message,
      statusCode: error.statusCode,
      fatal: true,
    })
}
export async function useArticlesHub({
  description = '',
}: {
  description?: MaybeRefOrGetter<string | undefined>
} = {}) {
  const { trackBreadcrumbClick } = useTrackBreadcrumbClick()
  const { setHead } = useHubHead()
  const { data, error } = await useFetchArticlesHub()

  handleError(error.value)

  setHead({ title: data.value?.title, description })

  return { hub: data, trackBreadcrumbClick }
}
