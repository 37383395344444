import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

export function useTrackBreadcrumbClick() {
  const { trackClick } = useTracking()

  function trackBreadcrumbClick(breadcrumbValue: string) {
    trackClick({
      name: 'breadcrumb',
      zone: 'hubBuyingGuide',
      value: {
        breadcrumbValue,
      },
    })
  }

  return { trackBreadcrumbClick }
}
