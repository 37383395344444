import { useAsyncData, useRoute, useRouteLocationWithLocale } from '#imports'

import {
  getHubFooterLinks,
  getHubPageInfos,
  getRootHubFooterLinks,
  getRootHubPageInfos,
} from '@backmarket/http-api/src/api-specs-acquisition/buying-guide/buying-guide'
import type { ArticlesHub } from '@backmarket/http-api/src/api-specs-acquisition/buying-guide/hub'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import { CMS } from '~/scopes/cms/routes-names'
import translations from '~/scopes/cms/shared-components/Breadcrumb/Breadcrumb.translations'
import { HOME } from '~/scopes/home/route-names'

import { removePattern, removePatternFromLink } from '../utils/removePattern'

const isRootHub = (route: ReturnType<typeof useRoute>) =>
  route.name === CMS.ARTICLE_ROOT_HUB

const buildPathParams = (route: ReturnType<typeof useRoute>) =>
  isRootHub(route) ? {} : { id: route.params.unifiedName }

async function fetchFooter(route: ReturnType<typeof useRoute>) {
  const footerEndpoint = isRootHub(route)
    ? getRootHubFooterLinks
    : getHubFooterLinks

  const pathParams = buildPathParams(route)

  try {
    const footerLinks = await $httpFetch(footerEndpoint, {
      pathParams,
    })

    const footerWithoutEmptyCategories = footerLinks
      .filter((link) => !isEmpty(link.subCategories))
      .map((item) =>
        removePatternFromLink({
          ...item,
          subCategories: item.subCategories.map(removePatternFromLink),
        }),
      )

    return footerWithoutEmptyCategories
  } catch (err) {
    return []
  }
}

function useFetchHubInfos() {
  const route = useRoute()
  const resolveLocalizedRoute = useRouteLocationWithLocale()
  const i18n = useI18n()

  function buildBreadcrumb(breadcrumb: ArticlesHub['breadcrumb']) {
    const [firstCrumb, ...otherCrumbs] = breadcrumb

    // NB: API returns "hub_buying_guide" for Root Hub and Category Hub pages
    // Because of this, we have to manually build the link for the Root Hub
    const rootHubCrumb = {
      link: resolveLocalizedRoute({ name: CMS.ARTICLE_ROOT_HUB }),
      title: firstCrumb.displayName,
    }

    const categoryHubsCrumbs = otherCrumbs.map(({ displayName, link }) => ({
      link: removePattern(link),
      title: displayName,
    }))

    return [
      {
        link: resolveLocalizedRoute({ name: HOME }),
        title: i18n(translations.home),
      },
      rootHubCrumb,
      ...categoryHubsCrumbs,
    ]
  }

  async function fetchHubInfos() {
    const endpoint = isRootHub(route) ? getRootHubPageInfos : getHubPageInfos
    const pathParams = buildPathParams(route)

    const {
      breadcrumb,
      buyingGuides,
      categories,
      featured,
      countryCode,
      intro,
      title,
    } = await $httpFetch(endpoint, {
      pathParams,
    })

    const mappedBuyingGuides = buyingGuides?.map(removePatternFromLink)
    const mappedCategories = categories
      ?.filter((cat) => !isEmpty(cat.featured))
      .map((cat) => ({
        ...cat,
        link: removePattern(cat.link),
        featured: cat.featured.map(removePatternFromLink),
      }))

    const mappedFeaturedArticles = featured?.map(removePatternFromLink)

    return {
      countryCode,
      intro,
      title,
      buyingGuides: mappedBuyingGuides,
      categories: mappedCategories,
      featured: mappedFeaturedArticles,
      breadcrumb: buildBreadcrumb(breadcrumb),
    }
  }

  return { fetchHubInfos }
}

export function useFetchArticlesHub() {
  const { fetchHubInfos } = useFetchHubInfos()
  const route = useRoute()

  return useAsyncData(async () => {
    const [footer, hubInfos] = await Promise.all([
      fetchFooter(route),
      fetchHubInfos(),
    ])

    return { footer, ...hubInfos }
  })
}
