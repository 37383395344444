<template>
  <div v-if="hub" class="mx-auto px-24 pb-32 lg:max-w-[1120px] lg:px-0">
    <RevBreadcrumb
      v-if="hub.breadcrumb"
      ariaLabel="breadcrumb"
      :breads="hub.breadcrumb"
      class="pb-24 md:pb-32"
      @click-breadcrumb="trackBreadcrumbClick"
    />

    <h1 class="heading-1 mb-8" hub-test="hub-title">
      {{ hub.title }}
    </h1>

    <div class="flex flex-col">
      <HubIntro ref="introRef" :intro="hub.intro" />

      <ArticleCardsCarousel
        v-if="hub.featured && hub.featured?.length > 0"
        :articles="mapFeaturedArticles(hub.featured, router, currentUrl)"
        class="mt-56"
        :title="i18n(translations.featuredArticlesTitle)"
      />

      <div v-for="category of hub.categories" :key="category.id" class="mt-56">
        <ArticleCardsCarousel
          :articles="mapFeaturedArticles(category.featured, router, currentUrl)"
          :cta="generateCTALink(category.link, category.ctaText)"
          :title="category.displayName"
        />
      </div>

      <ArticleCardsCollection
        v-if="hub.buyingGuides && hub.buyingGuides?.length > 0"
        :articles="mapFeaturedArticles(hub.buyingGuides, router, currentUrl)"
        class="mt-32"
        layout="grid"
      />
    </div>

    <CategoryTree
      v-if="formattedCategoryTreeProps?.categoryTree.length"
      v-bind="formattedCategoryTreeProps"
      :tracking="trackingParams"
    />
  </div>
</template>

<script setup lang="ts">
import { useRequestURL, useRouter } from '#imports'
import { computed, ref } from 'vue'

import type { Link } from '@backmarket/http-api'
import type { CategoryTreeProps } from '@backmarket/http-api/src/api-specs-content/models/category-tree'
import ArticleCardsCarousel from '@backmarket/nuxt-layer-cms/ArticleCard/ArticleCardsCarousel.vue'
import ArticleCardsCollection from '@backmarket/nuxt-layer-cms/ArticleCardsCollection.vue'
import CategoryTree from '@backmarket/nuxt-layer-cms/CategoryTree.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevBreadcrumb } from '@ds/components/Breadcrumb'

import { transformHref } from '../../../cms/helpers/link/transformHref'
import { CMS } from '../../../cms/routes-names'
import HubIntro from '../components/HubIntro/HubIntro.vue'
import { useArticlesHub } from '../composables/useArticlesHub'
import { mapFeaturedArticles } from '../utils/articleAdapter'

import translations from './ArticlesHub.translations'

const router = useRouter()
const currentUrl = useRequestURL()
const i18n = useI18n()
const introRef = ref<InstanceType<typeof HubIntro>>()

const { hub, trackBreadcrumbClick } = await useArticlesHub({
  description: () => introRef.value?.introTextContent,
})

const trackingParams = { name: 'discover_our_articles', zone: CMS.ARTICLE_HUB }

function generateCTALink(link?: Link, ctaText?: string) {
  if (!link?.href || !ctaText) return undefined

  return { link: { href: link.href }, label: ctaText }
}

// convert previous badoom format to the new one
const formattedCategoryTreeProps = computed<CategoryTreeProps | null>(() => {
  if (!hub.value?.footer) return null

  return {
    title: '',
    categoryTree: hub.value.footer.map((f) => {
      return {
        id: f.id.toString(),
        name: f.displayName,
        link: transformHref({
          href: f.link.href || '',
          currentUrl,
        }),
        subCategories: f.subCategories.map((sc) => {
          return {
            id: sc.id.toString(),
            name: sc.displayName,
            link: transformHref({
              href: sc.link.href || '',
              currentUrl,
            }),
          }
        }),
      }
    }),
  }
})
</script>
